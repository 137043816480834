body {
	background-color: #4b3d6d;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	padding: 0;
	margin: 0;
	border: 0;
}

h2 {
	text-align: center;
	margin-top: 15px;
	color: #ccccbe;
}

.header {
	background: linear-gradient(to right, #4da073, #efa621e6);
	width: 100%;
}

.container {
	margin-bottom: 70px;
	max-width: 1200px;
}

.navbar a {
	font-weight: bold;
	color: white;
}

.navbar a:hover {
	color: white;
	text-decoration: underline;
	cursor: pointer;
}

.navbar-brand > img {
	display: inline-block;
	width: 80px;
	margin-bottom: 2px;
	margin-right: 5px;
	border-radius: 2px;
}

/* anything that's a ul with a class nav and navbar-nav, inside a li item */
ul.nav.navbar-nav li {
	margin: 0 0.5rem;
}

.navbar {
	background-size: 100% 100%;
}

.home-head > img {
	display: block;
	width: 50vw;
	max-width: 300px;
	border-radius: 5px;
	margin: 1rem auto;
}

.intro {
	margin: 10px auto;
	max-width: 750px;
}

.intro p {
	text-align: justify;
}

/* Footer style */
.footer-container {
	background: linear-gradient(to right, #4da073, #efa621e6);
	width: 100%;
	position: fixed;
	bottom: 0;
}

.footer {
	width: 230px;
	margin: 15px auto;
	font-size: 1em;
}

/* For the sandwich button on small creens */
.toggle-button {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 24px;
	width: 30px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	box-sizing: border-box;
}

.toggle-button:focus {
	outline: none;
}

.toggle-button__line {
	width: 30px;
	height: 2px;
	background: white;
}

.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}

/* sidedrawer */
.side-drawer {
	background: linear-gradient(to bottom, #4da073, #efa621e6);
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	width: 40vmin;
	max-width: 350px;
	z-index: 200;
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
	transition: transform 0.4s ease-in-out;
}

.side-drawer.open {
	transform: translateX(0);
	-webkit-transform: translateX(0);
}

.side-drawer ul {
	height: 100%;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

.side-drawer li {
	margin: 0.5rem 0.5rem;
}

.side-drawer a {
	color: #fff;
	text-decoration: none;
	font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
	color: #fa923f;
}

.top_drawer-toggle-container {
	position: absolute;
	right: 15px;
	top: 15px;
}

@media screen and (min-width: 576px) {
	.side-drawer {
		display: none;
	}

	.top_drawer-toggle-container {
		display: none;
	}
}

p.intro {
	font-size: medium;
	font-weight: 700;
	text-align: center;
	margin-top: 1em;
	padding: 1em 1em;
	border-radius: 4px;
	color: #bdbcade8;
}

.image-container {
	margin: .5vw auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.image-container .image-item {
	flex-basis: 300px; /* minimal img width */
	margin: 1vw;
	align-items: center;
	margin: 1em;
	padding-top: .5em;
	border-radius: 10px;
	background-color: #c3c6c5;
}

.image-container div frame {
	margin-block-start: 10px;
	width: 280px;
	height: auto;
	transition: 1s;
}

/* gallery modal **/

.frame {
	width: 290px;
	height: 290px;
	/* border: 3px solid #ccc;
	background: #ccc; */
	margin: auto;
	/* padding: 10px 0; */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* .image-grid-item img { */
.image-container img {
	max-width: 280px;
	height: 280px;
	object-fit: scale-down;
	/* transition: 1.5s; */
}

.image-item p {
	font-size: 1rem;
	font-weight: 500;
	text-align: center;
}

@media screen and (min-width: 401px) {
	.person-img {
		width: 20vw;
		max-width: 180px;
	}
}

.modal-overlay {
	z-index: 1; /* Sit on top */
	left: 1%;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.175);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	position: relative;
	background-color: #e6e49b;
	margin: 10% auto;
	border: 10px solid #4e2806;
	width: 60vmin;
	min-width: 300px;
	WebkitOverflowScrolling: touch;
}

.close {
	position: absolute;
	right: 5px;
	top: 0px;
	color: #44105f;
	font-size: 2em;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.caption-container {
	width: 90%;
	margin: auto;
	text-align: center;
}

.caption-container p {
	font-weight: 500;
	font-size: 1.5rem;
}

.modal-image-container img {
	width: 100%;
	height: auto;
}

.prev-next {
	width: 100%;
	margin-top: 1em;
	text-align: center;
}

.prev-next button {
	background-color: #bb9482;
	font-size: 1.2em;
}

#prev {
	margin-bottom: 1em;
	margin-right: 1em;
}

#next {
	margin-left: 1em;
	margin-bottom: 1em;
}

/* video player */
.video-layout {
	margin-top: 15px;
	width: 100%;
}

.video-item {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 2vw;
}

.player-wrapper {
	flex-basis: 300px;
	border-radius: 15px;
	border-color: #e6e49b;
}

.video-item-intro {
	flex-basis: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 20px;
	margin: 10px 10px;
	align-items: flex-start;
	color: #bdbcade8;
}

.video-item-intro img {
	width: 100%;
	border-radius: 20px;
}

.story-photo {
	flex-basis: 250px;
}

.story-text {
	flex-basis: 350px;
}

.story-title {
	margin-bottom: 5px;
}

.age {
	margin-bottom: .5rem;
	font-size: .9rem;
}

.video-item-intro p {
	margin-block-start: 0.4rem;
	margin-block-end: 0.6rem;
}

.poem {
	margin-left: 15px;
	color: #ccccbe;
}

.mom-words {
	font-weight: 300;
	font-size: medium;
	font-style: italic;
}

.shira-words {
	font-weight: 500;
}
